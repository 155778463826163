import React from 'react';
import {
  Button, Modal, ModalBody, ModalFooter,
} from 'reactstrap';

interface DeleteConfirmationProps {
  name: string,
  isOpen: boolean,
  // eslint-disable-next-line no-unused-vars
  onDeleteClick: (e: React.MouseEvent<any, MouseEvent>) => void
  onToggle: () => void
}

export default function DeleteConfirmation(props: DeleteConfirmationProps) {
  const {
    name, isOpen, onDeleteClick, onToggle,
  } = props;

  return (
    <Modal isOpen={isOpen} toggle={onToggle} backdrop keyboard size="sm">
      <ModalBody>
        Really delete
        {' '}
        {name}
        ?
      </ModalBody>
      <ModalFooter>
        <Button id="DeleteButton" color="danger" onClick={onDeleteClick}>Delete</Button>
      </ModalFooter>
    </Modal>
  );
}
