import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Alert, Button, Col, Form, FormFeedback, FormGroup, Input, Label,
} from 'reactstrap';
import validateFetchResponse from '../validateFetchResponse';
import { LoadingSpinner } from './LoadingSpinner';
import { removeAllWhitespace, removeDashes } from '../utilities/InputCleaner';
import { useCookies } from 'react-cookie';
import { PaymentStatus } from './Enums/PaymentStatus';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ReCaptcha from './ReCaptcha';

interface BankAccountFormProps {
  amount: number,
  intuitPaymentsBaseUrl: string,
  orderGuid: string
}

interface BankAccountTokenFromIntuit {
  value: string
}

export default function BankAccountForm(props: BankAccountFormProps) {

  const [cookies] = useCookies(['csrfRequestToken']);
  const csrfRequestToken: string = cookies.csrfRequestToken;

  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(PaymentStatus.PageLoaded);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [name, setName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountType, setAccountType] = useState('');
  const [phone, setPhone] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');

  const [isCallingRecaptcha, setisCallingRecaptcha] = useState<boolean>(false);
  const [reCaptchaToken, setReCaptchaToken] = useState('');

  const moneyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });

  const getBankAccountToken = async (): Promise<BankAccountTokenFromIntuit | null> => {
    const cleanedName = name.trim();
    const cleanedPhone = removeAllWhitespace(removeDashes(phone));
    const cleanedAccountNumber = removeAllWhitespace(accountNumber);
    const cleanedRoutingNumber = removeAllWhitespace(routingNumber);

    const bankAccountData = {
      bankAccount: {
        name: cleanedName,
        phone: cleanedPhone,
        accountNumber: cleanedAccountNumber,
        routingNumber: cleanedRoutingNumber,
        accountType,
      },
    };

    return fetch(`${props.intuitPaymentsBaseUrl}quickbooks/v4/payments/tokens`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bankAccountData),
      })
      .then(validateFetchResponse)
      .then((response) => response.json())
      .then((json) => {
        const token: BankAccountTokenFromIntuit = { value: json.value };
        //console.log(`returned token ${token.value}`);
        return token;
      })
      .catch((error: Error) => {
        console.error(error.message);
        setErrorMessage('Invalid bank account. Is the above information correct?');
        setStatusMessage('');

        return null;
      });
  };

  const payNow = async () => {

    setIsLoading(true);

    const bankAccountToken = await getBankAccountToken();
    if (bankAccountToken === null) {
      setIsLoading(false);
      return;
    }

    fetch(
      `api/order/${props.orderGuid}/bankaccountpayment`,
      {
        method: 'POST',
        headers: {
          amount: props.amount.toString(),
          bankAccountToken: bankAccountToken.value,
          reCaptchaToken: reCaptchaToken,
          'X-CSRF-REQUEST-TOKEN': csrfRequestToken
        },
      },
    )
      .then(validateFetchResponse)
      .then(() => {
        setStatusMessage('Successfully paid! Redirecting to order...');
        setPaymentStatus(PaymentStatus.SuccessfullyPaid);
      })
      .catch((error: Error) => {
        setStatusMessage('');
        setErrorMessage(error.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {

    //console.log(`paymentStatus: ${paymentStatus}`);

    if (paymentStatus === PaymentStatus.PayWasClicked) {
      setisCallingRecaptcha(true);
      setPaymentStatus(PaymentStatus.WaitingOnRecaptcha);
      return;
    }

    if (paymentStatus === PaymentStatus.WaitingOnRecaptcha) {
      //console.log("waiting on reCAPTCHA...");
      if (isCallingRecaptcha === false && reCaptchaToken) {
        //console.log("we have a reCAPTCHA token, paying!");
        payNow();
        setPaymentStatus(PaymentStatus.WaitingOnPaymentProcessing);
      }
      return;
    }

  }, [isCallingRecaptcha, paymentStatus, reCaptchaToken]);

  if (paymentStatus === PaymentStatus.SuccessfullyPaid) {
    const orderUrl = `/order/${props.orderGuid}`;
    return <Redirect to={orderUrl} />;
  }

  return (
    <div>
      <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setPaymentStatus(PaymentStatus.PayWasClicked);
      }}>
        <FormGroup row className="ml-0">
          <Label sm={4}>Name</Label>
          <Col sm={8}>
            <Input type="text" name="name" placeholder="Name on account" required maxLength={64} value={name} onChange={(e) => setName(e.target.value)} />
            <FormFeedback valid tooltip>Sweet! that name is available</FormFeedback>
          </Col>
        </FormGroup>
        <FormGroup row className="mt-4 ml-0">
          <Label sm={4}>Phone</Label>
          <Col sm={8}>
            <Input type="tel" name="phone" inputMode="numeric" required pattern="[0-9]{3}-?[0-9]{3}-?[0-9]{4}" minLength={10} maxLength={12} placeholder="123-456-7890" value={phone} onChange={(e) => setPhone(e.target.value)} />
          </Col>
        </FormGroup>
        <FormGroup row className="mt-4 ml-0">
          <Label sm={4}>Routing Number</Label>
          <Col sm={8}>
            <Input type="tel" name="routingNumber" inputMode="numeric" required minLength={9} maxLength={9} pattern="[0-9]*" placeholder="(9 digits, no spaces)" value={routingNumber} onChange={(e) => setRoutingNumber(e.target.value)} />
          </Col>
        </FormGroup>
        <FormGroup row className="mt-4 ml-0">
          <Label sm={4}>Account Number</Label>
          <Col sm={8}>
            <Input type="tel" name="accountNumber" inputMode="numeric" required minLength={4} maxLength={17} pattern="[0-9]*" placeholder="(4-17 digits, no spaces)" value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
          </Col>
        </FormGroup>
        <FormGroup row className="mt-4 ml-0">
          <Label sm={4}>Account Type</Label>
          <Col sm={8}>
            <Input type="select" name="accountType" required value={accountType} onChange={(e) => setAccountType(e.target.value)}>
              <option value="">Make a selection...</option>
              <option value="PERSONAL_CHECKING">Personal Checking</option>
              <option value="PERSONAL_SAVINGS">Personal Savings</option>
              <option value="BUSINESS_CHECKING">Business Checking</option>
              <option value="BUSINESS_SAVINGS">Business Savings</option>
            </Input>
          </Col>
        </FormGroup>
        {
          isLoading
          && (
            <FormGroup row>
              <LoadingSpinner />
            </FormGroup>
          )
        }
        {
          statusMessage !== '' && (
            <FormGroup row>
              <Alert color="success" fade isOpen={statusMessage !== ''}>{statusMessage}</Alert>
            </FormGroup>
          )
        }
        {
          errorMessage !== '' && (
            <FormGroup row>
              <Alert color="danger" fade isOpen={errorMessage !== ''}>{errorMessage}</Alert>
            </FormGroup>
          )
        }
        <FormGroup row className="mb-3 mt-3">
          <Col>
            <Button type="submit" color="primary">
              {`Pay ${moneyFormatter.format(props.amount)}`}
            </Button>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col>
            <Label className="text-muted" size="sm">Payments are processed by <a href="https://quickbooks.intuit.com/payments/" target="_blank" rel='noreferrer'>Intuit Payments, Inc</a>.</Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col>
            <GoogleReCaptchaProvider reCaptchaKey="6LdbPjQhAAAAACSNl_LdLJsc9PmQU0fyicrRLbmz">
              <ReCaptcha nameOfUserAction={'payWithCreditCard'} isCallingReCaptcha={isCallingRecaptcha} onVerified={function (token: string): void {
                setReCaptchaToken(token);
                setisCallingRecaptcha(false);
              }} />
            </GoogleReCaptchaProvider>
          </Col>
        </FormGroup>
      </Form>
    </div>
  );
}
