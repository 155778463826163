import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDropzone } from 'react-dropzone';
import { Card } from 'reactstrap';
import validateFetchResponse from '../../validateFetchResponse';
import authService from '../Api-Authorization/AuthorizeService';

enum UploadStatus {
  Uploading,
  Uploaded,
  Error
}

interface UploaderProps {
  orderId: number,
  relativePath: string,
  onUploaded: () => void,
  onError: (errorMessage: string) => void,
  onStatusChange: (status: string) => void
}

// TODO: ideally retrieve from an API, which could share the same list as SecuredFile.cs
const ALLOWED_EXTENSIONS: Array<string> = ['.ai', '.bmp', '.doc', '.docx', '.dwg', '.gif', '.jpeg', '.jpg', '.ind', '.indd', '.mp4', '.nef', '.pdf', '.png', '.psd', '.skp', '.svg', '.tif', '.tiff', '.txt', '.vsd', '.vsdx', '.xlsx', '.xyz', '.zip'];

const ONE_MEGABYTE: number = 1000000;

// TODO: ideally retrieve from an API, which could share the same size as AdminOrderController.cs
const UPLOAD_SIZE_LIMIT: number = (250 * ONE_MEGABYTE);

export function Uploader(props: UploaderProps) {
  const COMMA_SPACE = ', ';

  const [cookies] = useCookies(['csrfRequestToken']);
  const [files, setFiles] = useState<Map<string, UploadStatus>>(new Map<string, UploadStatus>());
  const [errors, setErrors] = useState<Array<string>>(new Array<string>());
  const [uploadCompleteCounter, setUploadCompleteCounter] = useState<number>(0);
  const [token, setToken] = useState<string | null>(null);

  const onDrop = useCallback(acceptedFiles => {

    if (acceptedFiles.length === 0) return;

    const csrfRequestToken = cookies.csrfRequestToken;

    const uploads: Array<Promise<Response | void>> = [];

    acceptedFiles.map((file: File) => {
      const hasInvalidExtension: boolean = ALLOWED_EXTENSIONS.filter((ext) => file.name.toLowerCase().endsWith(ext)).length === 0;
      if (hasInvalidExtension) {
        setFiles((prevState) => (new Map<string, UploadStatus>(prevState)).set(file.name, UploadStatus.Error));
        setErrors((prevState) => [...prevState, `File "${file.name}" has invalid extension`]);
        return;
      }

      if (file.size > UPLOAD_SIZE_LIMIT) {
        setFiles((prevState) => (new Map<string, UploadStatus>(prevState)).set(file.name, UploadStatus.Error));
        setErrors((prevState) => [...prevState, `File "${file.name}" exceeds the upload size limit`]);
        return;
      }

      setFiles((prevState) => (new Map<string, UploadStatus>(prevState)).set(file.name, UploadStatus.Uploading));

      const formData = new FormData();
      formData.append('file', file);

      console.log("uploading file: ", file);

      uploads.push(
        fetch(
          `api/adminorder/${props.orderId}/files/upload`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              relativePath: props.relativePath,
              "X-CSRF-REQUEST-TOKEN": csrfRequestToken,
            },
            body: formData,
          },
        )
          .then(validateFetchResponse)
          .then(() => {
              setFiles((prevState) => (new Map<string, UploadStatus>(prevState)).set(file.name, UploadStatus.Uploaded));
              props.onUploaded();
            // if(Math.random() > 0.5){ throw new Error(`Fake error with ${file.name}`); }
          })
          .catch((error: Error) => {
            setFiles((prevState) => (new Map<string, UploadStatus>(prevState)).set(file.name, UploadStatus.Error));
            setErrors((prevState) => [...prevState, error.message]);
          }),
      );
    });

    Promise.all(uploads).then(() => setUploadCompleteCounter((count) => count + 1));

  }, [token, props.orderId, props.relativePath]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });;

  useEffect(() => {
    const getToken = async () => {
      const newToken = await authService.getAccessToken();
      setToken(newToken);
    };

    getToken();
  }, []);

  useEffect(() => {
    if (files.size === 0) {
      return;
    }

    let uploadedCount = 0;

    files.forEach((status: UploadStatus) => {
      if (status === UploadStatus.Uploaded) {
        uploadedCount++;
      }
    });

    props.onStatusChange(`Successfully uploaded ${uploadedCount} of ${files.size} files`);
    props.onUploaded();

    setErrors(new Array<string>());
    setFiles(new Map<string, UploadStatus>());
  }, [uploadCompleteCounter]);

  useEffect(() => updateStatus(files), [files]);

  useEffect(() => {
    if (errors.length === 0) {
      return;
    }

    let errorList = '';
    errors.forEach((error) => {
      errorList += `${error}${COMMA_SPACE}`;
    });
    errorList = errorList.substring(0, errorList.length - COMMA_SPACE.length);

    props.onError(`Errors: ${errorList}`);
  }, [errors]);

  const updateStatus = (currentFiles: Map<string, UploadStatus>) => {
    const totalFiles: number = currentFiles.size;

    if (totalFiles === 0) {
      return;
    }

    let completedFiles: number = 0;
    currentFiles.forEach((status: UploadStatus, fileName: string) => {
      if (status === UploadStatus.Uploaded) {
        completedFiles++;
      }
    });

    props.onStatusChange(`Uploading... ${completedFiles} of ${totalFiles} completed`);
  };

  const bodyClass = isDragActive ? 'text-center bg-white' : 'text-center bg-light';

  return (
    <div>
      <Card body className={bodyClass}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <p>Drag and drop files here, or click to select</p>
          }
        </div>
      </Card>
    </div>
  );
}
