import React, { useEffect } from 'react';
import { ApplicationPaths } from './Api-Authorization/ApiAuthorizationConstants';

export default function RedirectToChangePassword() {
  useEffect(() => {

    // 2020-08-08: stole this code from Login.js: redirectToApiAuthorizationPath

    const redirectUrl = `${window.location.origin}${ApplicationPaths.IdentityChangePasswordPath}`;
    // It's important that we do a replace here so that when the user hits the back arrow on the
    // browser he gets sent back to where it was on the app instead of to an endpoint on this
    // component.
    window.location.replace(redirectUrl);
  }, []);

  return (<div>It's time to change your password. Redirecting...</div>);
}
