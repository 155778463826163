import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Table, Input, Form, Row, Col,
} from 'reactstrap';
import { TablePaging } from '../TablePaging';
import { TableSortLabel } from '../TableSortLabel';
import { OrderAdminDto } from './Models/OrderAdminDto';
import { LoadingSpinner } from '../LoadingSpinner';
import authService from '../Api-Authorization/AuthorizeService';
import cleanUrl from '../../utilities/cleanUrl';
import sortHelper from '../../utilities/sortHelper';
import validateFetchResponse from '../../validateFetchResponse';
import RedirectToChangePassword from '../RedirectToChangePassword';

export default function AdminOrderList() {
  const PAGE_SIZE = 50;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isPasswordExpired, setIsPasswordExpired] = useState<boolean>(false);
  const [orders, setOrders] = useState<Array<OrderAdminDto>>([]);
  const [filterText, setFilterText] = useState<string>('');
  const [filteredOrders, setFilteredOrders] = useState<Array<OrderAdminDto>>([]);

  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [pagesCount, setPagesCount] = useState<number>(0);

  const [sortName, setSortName] = useState<string>('Order ID');
  const [sortAscending, setSortAscending] = useState<boolean>(false);

  const handlePageChange = (e: React.MouseEvent<HTMLAnchorElement>, index: number) => {
    e.preventDefault();
    setCurrentPageIndex(index);
  };

  const handleSortChange = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    newSortName: string,
    newSortAscending: boolean,
  ) => {
    e.preventDefault();
    setSortName(newSortName);
    setSortAscending(newSortAscending);
  };

  function sortOrders(a: OrderAdminDto, b: OrderAdminDto) {
    let result: number = 0;

    switch (sortName) {
      case 'Order ID':
        result = sortHelper(a.id, b.id, sortAscending);
        break;
      case 'Address':
        result = sortHelper(a.address, b.address, sortAscending);
        break;
      case 'First Name':
        result = sortHelper(a.firstName, b.firstName, sortAscending);
        break;
      case 'Last Name':
        result = sortHelper(a.lastName, b.lastName, sortAscending);
        break;
      case 'Email':
        result = sortHelper(a.email, b.email, sortAscending);
        break;
      case 'Invoice Link':
        result = sortHelper(a.quickbooksInvoiceUrl, b.quickbooksInvoiceUrl, sortAscending);
        break;
      case 'Delivered':
        result = sortHelper(a.queuedForDeliveryDateTime, b.queuedForDeliveryDateTime, sortAscending);
        break;
      case 'Paid':
        result = sortHelper(a.paidDateTime, b.paidDateTime, sortAscending);
        break;
      case 'Test Order':
        result = sortHelper(a.testOrder, b.testOrder, sortAscending);
        break;
    }

    return result;
  }

  useEffect(() => {
    const getOrders = async () => {
      const authToken: string | null = await authService.getAccessToken();
      const requestHeaders: HeadersInit = !authToken ? { } : { "Authorization": `Bearer ${authToken}` };

      fetch(
        'api/adminorder',
        {
          method: 'GET',
          headers: requestHeaders,
        },
      )
        .then(validateFetchResponse)
        .then((response) => response.json())
        .then((json) => {
          setOrders(json);
          updateFilter(json, filterText);
          setIsLoading(false);
        })
        .catch((error: Error) => console.error(error.message));
    };

    getOrders();
  }, []);

  useEffect(() => {
    const checkPasswordExpiration = async () => {
      const authToken: string | null = await authService.getAccessToken();
      const requestHeaders: HeadersInit = !authToken ? { } : { "Authorization": `Bearer ${authToken}` };

      fetch(
        'api/admin/passwordexpiration',
        {
          method: 'GET',
          headers: requestHeaders,
        },
      )
        .then(validateFetchResponse)
        .then((response) => response.json())
        .then((json) => {
          setIsPasswordExpired(json);
        })
        .catch((error: Error) => console.error(error.message));
    };

    checkPasswordExpiration();
  }, []);

  function updateFilter(currentOrders: Array<OrderAdminDto>, newFilterText: string) {
    setFilterText(newFilterText);

    const filtered = currentOrders.filter(
      (order: OrderAdminDto) => {
        const filterTextLower = newFilterText.toLowerCase();
        const addressLower = order.address.toLowerCase();

        return (
          order.id.toString().includes(filterTextLower)
          || addressLower.includes(filterTextLower)
          || order.firstName.toLowerCase().includes(filterTextLower)
          || order.lastName.toLowerCase().includes(filterTextLower)
          || order.email.toLowerCase().includes(filterTextLower)
          || (filterTextLower === 'invoice' && order.quickbooksInvoiceUrl !== '')
          || (filterTextLower === 'paid' && order.paidDateTime == null)
          || (filterTextLower === 'test' && order.testOrder)
        );
      },
    );
    setFilteredOrders(filtered);
    setCurrentPageIndex(0);
    setPagesCount(Math.ceil(filtered.length / PAGE_SIZE));
  }

  if(isPasswordExpired)
    return <RedirectToChangePassword />;

  return (
    <div>
      <h2 className="mb-4">Orders Dashboard</h2>
      {
        isLoading
        && <LoadingSpinner />
      }
      {
        orders.length > 0
        && (
          <div>
            <Form>
              <Row form className="mb-2">
                <Col md={8} />
                <Col md={4} className="text-right-sm">
                  <Input type="search" name="search" id="orderFilter" placeholder="Search" bsSize="sm" value={filterText} onChange={(e) => updateFilter(orders, e.target.value)} />
                </Col>
              </Row>
            </Form>
            <Table hover striped size="sm">
              <thead>
                <tr>
                  <td><TableSortLabel label="Order ID" sortName={sortName} sortAscending={sortAscending} onSortChange={handleSortChange} /></td>
                  <td><TableSortLabel label="Address" sortName={sortName} sortAscending={sortAscending} onSortChange={handleSortChange} /></td>
                  <td><TableSortLabel label="First Name" sortName={sortName} sortAscending={sortAscending} onSortChange={handleSortChange} /></td>
                  <td><TableSortLabel label="Last Name" sortName={sortName} sortAscending={sortAscending} onSortChange={handleSortChange} /></td>
                  <td><TableSortLabel label="Email" sortName={sortName} sortAscending={sortAscending} onSortChange={handleSortChange} /></td>
                  <td><TableSortLabel label="Invoice Link" sortName={sortName} sortAscending={sortAscending} onSortChange={handleSortChange} /></td>
                  <td><TableSortLabel label="Delivered" sortName={sortName} sortAscending={sortAscending} onSortChange={handleSortChange} /></td>
                  <td><TableSortLabel label="Paid" sortName={sortName} sortAscending={sortAscending} onSortChange={handleSortChange} /></td>
                  <td><TableSortLabel label="Test Order" sortName={sortName} sortAscending={sortAscending} onSortChange={handleSortChange} /></td>
                </tr>
              </thead>
              <tbody>
                {filteredOrders
                  .sort(sortOrders)
                  .slice(currentPageIndex * PAGE_SIZE, (currentPageIndex + 1) * PAGE_SIZE)
                  .map((order: OrderAdminDto, index: number) => (
                    <tr key={index} className={order.testOrder ? 'text-muted' : ''}>
                      <th scope="row"><Link to={`/admin/order/${order.id}`}>{order.id}</Link></th>
                      <td>{order.address}</td>
                      <td>{order.firstName}</td>
                      <td>{order.lastName}</td>
                      <td>{order.email}</td>
                      <td>
                        {
                          order.quickbooksInvoiceUrl
                          && <a href={cleanUrl(order.quickbooksInvoiceUrl)}>open</a>
                        }
                      </td>
                      <td>
                        {
                          (order.queuedForDeliveryDateTime !== null && order.queuedForDeliveryDateTime !== undefined)
                          && new Date(order.queuedForDeliveryDateTime).toLocaleDateString()
                        }
                      </td>
                      <td>
                        {
                          (order.paidDateTime !== null && order.paidDateTime !== undefined)
                          && new Date(order.paidDateTime).toLocaleDateString()
                        }
                      </td>
                      <td>{order.testOrder ? 'Yes' : ''}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <TablePaging currentPageIndex={currentPageIndex} pagesCount={pagesCount} onPageChange={handlePageChange} />
          </div>
        )
      }
    </div>
  );
}
