import React, { useState } from 'react';
import { faFileAlt, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OnIconClickEvent } from './OnIconClickEvent';
import DeleteConfirmation from './DeleteConfirmation';
import { OnRowClickEvent } from './OnRowClickEvent';

interface FileProps {
  name: string,
  hasThumbnail: boolean,
  size?: number,
  writeAccess: boolean,
  onDeleteClick: OnIconClickEvent,
  onDownloadClick: OnIconClickEvent,
  onRowClick: OnRowClickEvent
}

export function FileRow(props: FileProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const toggleModal = () => setModalVisible(!modalVisible);

  const handleDelete = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setModalVisible(false);
    props.onDeleteClick(e, props.name);
  };

  function rowClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.preventDefault();

    if (props.hasThumbnail === false) return;

    // TODO: find if trash can?
    // if(e.target instanceof SVGSVGElement || e.target instanceof SVGPathElement){
    //   let test = (SVGSVGElement)e.target;
    //   console.log(`FolderRow click target.getElementById: ${test.getElementById("DeleteFolder")}`);
    // }

    const iconClickedSoShouldIgnore = (e.target instanceof SVGElement);
    if (iconClickedSoShouldIgnore) return;

    props.onRowClick(e, props.name);
  }

  const sizeFormatted = formatBytes(props.size!);

  return (
    <tr className="d-flex" onClick={rowClick}>
      <td className="col-1"><FontAwesomeIcon icon={faFileAlt} className="text-muted" /></td>
      <td className="col-6">{props.name}</td>
      <td className="col-3">{sizeFormatted}</td>
      <td className="col-2 text-right">
        <FontAwesomeIcon icon={faDownload} onClick={(e) => props.onDownloadClick(e, props.name)} className="text-muted mr-2" size="lg" />
        {
          props.writeAccess
          && (
          <span>
            <FontAwesomeIcon id="DeleteIcon" icon={faTrashAlt} onClick={toggleModal} className="text-muted" size="lg" />
            <DeleteConfirmation isOpen={modalVisible} name={props.name} onDeleteClick={handleDelete} onToggle={toggleModal} />
          </span>
          )
        }
      </td>
    </tr>
  );
}

function formatBytes(bytes: number, decimals: number = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
