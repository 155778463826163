import React, { useEffect, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import {
  Col, Form, FormGroup, Label, Row, Table, Button, ButtonGroup,
} from 'reactstrap';
import BankAccountForm from './BankAccountForm';
import CreditCardForm from './CreditCardForm';
import { LoadingSpinner } from './LoadingSpinner';
import { OrderForPaymentDto } from './Models/OrderForPaymentDto';
import validateFetchResponse from '../validateFetchResponse';
import { NotFound } from './NotFound';
import { useCookies } from 'react-cookie';

interface PaymentProps {
  guid: string;
}

enum PaymentMethod {
  CreditCard,
  BankAccount
}

export function Payment() {
  const { guid } = useParams<PaymentProps>();

  const [cookies] = useCookies(['csrfRequestToken']);
  const csrfRequestToken: string = cookies.csrfRequestToken;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isNotFound, setIsNotFound] = useState<boolean>(false);

  const [orderForPayment, setOrderForPayment] = useState<OrderForPaymentDto | undefined>();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PaymentMethod.CreditCard);

  const formatDateOrDateString = (input: Date | string) => {
    const dateToFormat = new Date(input);
    return new Intl.DateTimeFormat('en-US').format(dateToFormat);
  };

  const moneyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });

  useEffect(() => {
    const getPaymentOrder = async () => {
        fetch(`api/order/${guid}/payment`, {
            method: 'GET',
            headers: {
                'X-CSRF-REQUEST-TOKEN': csrfRequestToken,
            }
        })
        .then(validateFetchResponse)
        .then((response) => response.json())
        .then((json) => {
          setOrderForPayment(json);
          setIsLoading(false);
        })
        .catch((error: Error) => {
          console.log(error.message);
          setIsNotFound(true);
        });
    };

    getPaymentOrder();
  }, []);

  if (isNotFound) {
    return <NotFound />;
  }

  if (orderForPayment) {

    const alreadyPaid: boolean = (orderForPayment.paidDateTime !== null && orderForPayment.paidDateTime !== undefined);

    if (alreadyPaid || orderForPayment.allowAccessBeforePayment) {
      const orderUrl = `/order/${guid}`;
      return <Redirect to={orderUrl} />;
    }
  }

  return (
    <div>
      {
        (orderForPayment)
        && (
          <p className="mb-3">
            <Link to={`/customer/${orderForPayment.customerGuid}`}>&larr; View all my orders</Link>
          </p>
        )
      }
      <h1>Payment</h1>
      <p className="text-muted mt-3">
        Please submit payment to view order files.
      </p>
      {
        isLoading
        && <LoadingSpinner />
      }
      {
        (orderForPayment)
        && (
          <div>
            <Row>
              <Col>
                <Row>
                  <Label sm={3}>Balance Due</Label>
                  <Col sm={9}>
                    <Label className="text-muted">{moneyFormatter.format(orderForPayment.balanceDue)}</Label>
                  </Col>
                </Row>
                <Row>
                  <Label sm={3}>Due Date</Label>
                  <Col sm={9}>
                    <Label className="text-muted">{formatDateOrDateString(orderForPayment.dueDate)}</Label>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Label sm={3} className="text-nowrap">Payment Method</Label>
                  <Col sm={9}>
                    <ButtonGroup>
                      <Button color="success" size="sm" outline={paymentMethod !== PaymentMethod.CreditCard} onClick={() => setPaymentMethod(PaymentMethod.CreditCard)}>Credit Card</Button>
                      {' '}
                      <Button color="success" size="sm" outline={paymentMethod !== PaymentMethod.BankAccount} onClick={() => setPaymentMethod(PaymentMethod.BankAccount)}>Bank Account</Button>
                    </ButtonGroup>
                  </Col>
                </Row>
                <Row>
                  {
                    paymentMethod === PaymentMethod.CreditCard
                      ? <CreditCardForm amount={orderForPayment.balanceDue} customerGuid={orderForPayment.customerGuid} intuitPaymentsBaseUrl={orderForPayment.intuitPaymentsBaseUrl} orderGuid={guid} />
                      : <BankAccountForm amount={orderForPayment.balanceDue} intuitPaymentsBaseUrl={orderForPayment.intuitPaymentsBaseUrl} orderGuid={guid} />
                  }
                </Row>
              </Col>
              <Col>
                <Form>
                  <FormGroup row>
                    <h4>Order Summary</h4>
                  </FormGroup>
                  <FormGroup row className="my-0">
                    <Label sm={3}>Order #</Label>
                    <Col sm={9}>
                      <Label className="text-muted">{orderForPayment.id}</Label>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="my-0">
                    <Label sm={3}>Address</Label>
                    <Col sm={9}>
                      <Label className="text-muted">{orderForPayment.address}</Label>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="my-0">
                    <Label sm={3}>Name</Label>
                    <Col sm={9}>
                      <Label className="text-muted">{orderForPayment.name}</Label>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="my-0">
                    <Label sm={3}>Email</Label>
                    <Col sm={9}>
                      <Label className="text-muted">{orderForPayment.email}</Label>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="my-0">
                    <Label sm={3}>Invoice #</Label>
                    <Col sm={9}>
                      <Label className="text-muted">{orderForPayment.invoiceNumber}</Label>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="mt-4">
                    <Table striped size="sm">
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderForPayment.lineItems.map((lineItem) => {
                          const isTotalLine = lineItem.description.toLowerCase().includes('total') && (lineItem.description.toLowerCase().includes('sub') === false);

                          return (
                            <tr key={lineItem.id} className={isTotalLine ? 'font-weight-bold' : ''}>
                              <td>{lineItem.description}</td>
                              <td>{moneyFormatter.format(lineItem.amount)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </div>
        )
      }
    </div>
  );
}
