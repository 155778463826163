export default function cleanUrl(dangerousUrl: string): string {

    if(dangerousUrl.trim() === "") return "";

    const url: URL = new URL(dangerousUrl);
    // console.log(`dangerousUrl: ${dangerousUrl}`);
    // console.log(`url: ${url.toString()}`);

    if(url.protocol === 'http:' || url.protocol === 'https:')
        return url.toString();
    
    // console.log(`returning blank...`);
    return "";
  }