import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AdminOrder } from './components/Admin/AdminOrder';
import AdminOrderList from './components/Admin/AdminOrderList';
import { Layout } from './components/Layout';
import { NotFound } from './components/NotFound';
import { Order } from './components/Order';
import { Customer } from './components/Customer';
import { Payment } from './components/Payment';
import RedirectToMainSite from './components/RedirectToMainSite';
import AuthorizeRoute from './components/Api-Authorization/AuthorizeRoute';
import { ApplicationPaths } from './components/Api-Authorization/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from './components/Api-Authorization/ApiAuthorizationRoutes';

import './custom.css';

export default function App() {
  return (
    <Layout>
      <Switch>
        <Route exact path="/" component={RedirectToMainSite} />
        <AuthorizeRoute exact path="/admin/order" component={AdminOrderList} />
        <AuthorizeRoute exact path="/admin/order/:orderId" component={AdminOrder} />
        <Route exact path="/customer/:customerGuid" component={Customer} />
        <Route exact path="/order/:guid" component={Order} />
        <Route exact path="/order/:guid/payment" component={Payment} />
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
}
