import React, { useState } from 'react';
import {
  Button, Input, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';

interface CreateFolderProps {
  isOpen: boolean,
  onCreateConfirmed: (folderName: string) => void
  onToggle: () => void
}

export function CreateFolder(props: CreateFolderProps) {
  const [newFolderName, setNewFolderName] = useState<string>('');

  const handleCreate = (event: React.MouseEvent<any, MouseEvent>) => {
    event.preventDefault();

    const cleanedFolderName = newFolderName.trim();
    if (cleanedFolderName === '') return;

    setNewFolderName('');
    props.onCreateConfirmed(cleanedFolderName);
  };

  const handleToggle = (event: React.KeyboardEvent<any> | React.MouseEvent<any, MouseEvent>) => {
    event.preventDefault();

    setNewFolderName('');
    props.onToggle();
  };

  const handleChangeFolderName = (event: React.ChangeEvent<HTMLInputElement>) => setNewFolderName(event.target.value);

  return (
    <Modal isOpen={props.isOpen} toggle={handleToggle} backdrop keyboard size="sm">
      <ModalHeader>
        Add New Folder
      </ModalHeader>
      <ModalBody>
        <Input type="text" placeholder="Folder name" value={newFolderName} onChange={handleChangeFolderName} />
      </ModalBody>
      <ModalFooter>
        <Button id="CreateButton" color="primary" onClick={handleCreate}>Create</Button>
      </ModalFooter>
    </Modal>
  );
}
