export default function sortHelper(a: any, b: any, sortAscending: boolean) {
  let aCleaned = a;
  let bCleaned = b;

  if (typeof a === 'string') aCleaned = a.toLowerCase().trim();

  if (typeof b === 'string') bCleaned = b.toLowerCase().trim();

  aCleaned = aCleaned ?? '';
  bCleaned = bCleaned ?? '';

  let result: number = 0;
  result = (
    (aCleaned < bCleaned)
      ? -1
      : (
        (aCleaned > bCleaned) ? 1 : 0
      )
  );

  result = (sortAscending ? result : (result * -1));
  // console.log(`sortHelper: ${aCleaned} (${typeof aCleaned}), ${bCleaned} (${typeof bCleaned}), sortAscending: ${sortAscending}, result: ${result}`);
  return result;
}
