import React, { useEffect, useState } from 'react';
import {
  Col, Button, Form, FormGroup, Label, Input, Alert,
} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { LoadingSpinner } from '../LoadingSpinner';
import { FileBrowser } from '../FileBrowser/FileBrowser';
import { OrderAdminDto } from './Models/OrderAdminDto';
import { PhotographerDto } from './Models/PhotographerDto';
import authService from '../Api-Authorization/AuthorizeService';
import cleanUrl from '../../utilities/cleanUrl';
import validateFetchResponse from '../../validateFetchResponse';
import { NotFound } from '../NotFound';
import { useCookies } from 'react-cookie';
import { fileDownload } from '../FileBrowser/fileDownload';

interface AdminOrderProps {
  orderId: string;
}

export function AdminOrder() {
  const { orderId } = useParams<AdminOrderProps>();

  const [cookies] = useCookies(['csrfRequestToken']);
  const csrfRequestToken: string = cookies.csrfRequestToken;
  const [isDelivering, setIsDelivering] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isGeneratingInvoicePdf, setIsGeneratingInvoicePdf] = useState<boolean>(false);
  const [isLoadingPhotographers, setIsLoadingPhotographers] = useState<boolean>(true);
  const [isNotFound, setIsNotFound] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [order, setOrder] = useState<OrderAdminDto | undefined>();
  const [photographers, setPhotographers] = useState<Array<PhotographerDto>>([]);

  useEffect(() => {
    const getOrder = async () => {

      const authToken: string | null = await authService.getAccessToken();

      fetch(
        `api/adminorder/${orderId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${authToken}`,
            'X-CSRF-REQUEST-TOKEN': csrfRequestToken,
          },
        },
      )
        .then(validateFetchResponse)
        .then((response) => response.json())
        .then((json) => {
          setOrder(json);
          setIsLoading(false);
          setErrorMessage('');
        })
        .catch((error: Error) => {
          setErrorMessage(error.message);
          setIsNotFound(true);
        });
    };

    getOrder();
  }, [isDelivering]);

  useEffect(() => {

    if (!order) {
      return;
    }

    const getPhotographers = async () => {
      const authToken: string | null = await authService.getAccessToken();

      fetch(
        `api/admin/photographer`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${authToken}`,
            'X-CSRF-REQUEST-TOKEN': csrfRequestToken,
          },
        },
      )
        .then(validateFetchResponse)
        .then((response) => response.json())
        .then((json) => {
          setPhotographers(json);
          setIsLoadingPhotographers(false);
        })
        .catch((error: Error) => {
          console.log(error.message);
          setIsNotFound(true);
        });
    };

    getPhotographers();
  }, [order]);

  const handleDownloadInvoicePdf = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

    e.preventDefault();

    setIsGeneratingInvoicePdf(true);

    const authToken: string | null = await authService.getAccessToken();

    fetch(
      `api/adminorder/${orderId}/invoicepdf`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'X-CSRF-REQUEST-TOKEN': csrfRequestToken,
        },
      },
    )
      .then(validateFetchResponse)
      .then((response) => response.blob())
      .then((blob) => fileDownload(blob, `Invoice BFP${orderId}.pdf`))
      .catch((error: Error) => setErrorMessage(error.message))
      .finally(() => setIsGeneratingInvoicePdf(false));
  };

  const updateDeliveryNotes = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist(); // so we can acccess asynchronously below

    setOrder(
      (prevState: OrderAdminDto | undefined) => {
        const updatedOrder: OrderAdminDto = { ...prevState! };
        updatedOrder.deliveryNotes = event.target.value;

        return updatedOrder;
      },
    );
  };

  const updateVirtualTourUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist(); // so we can acccess asynchronously below

    setOrder(
      (prevState: OrderAdminDto | undefined) => {
        const updatedOrder: OrderAdminDto = { ...prevState! };
        updatedOrder.virtualTourUrl = event.target.value;

        return updatedOrder;
      },
    );
  };

  const queueForDelivery = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!order) {
      return;
    }

    setIsDelivering(true);

    const authToken: string | null = await authService.getAccessToken();

    const deliveryData = {
      deliveryNotes: order.deliveryNotes,
      virtualTourUrl: order.virtualTourUrl,
    };

    fetch(
      `api/adminorder/${orderId}/deliver`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
          'X-CSRF-REQUEST-TOKEN': csrfRequestToken,
        },
        body: JSON.stringify(deliveryData),
      },
    )
      .then(validateFetchResponse)
      .catch((error: Error) => {
        console.error(error.message);
        setErrorMessage(`Failed to queue order for delivery. ${error.message}`);
      })
      .finally(() => {
        setIsDelivering(false);
      });
  };

  if (isNotFound) {
    return <NotFound />;
  }

  let alreadyDelivered: boolean = false;
  let deliverySection: JSX.Element = <span />;

  if (order) {

    alreadyDelivered = (order.queuedForDeliveryDateTime !== null && order.queuedForDeliveryDateTime !== undefined);

    if (alreadyDelivered) {
      deliverySection = (
        <FormGroup row>
          <Label sm={3}>Queued for Delivery On</Label>
          <Col sm={9}>
            <Label className="text-muted">{new Date(order.queuedForDeliveryDateTime!).toLocaleDateString()}</Label>
          </Col>
        </FormGroup>
      );
    } else {
      deliverySection = (
        <div>
          <FormGroup check row>
            <Col sm={{ size: 9, offset: 3 }}>
              <Button color="primary" onClick={queueForDelivery} disabled={isDelivering || (order.canEditOrder === false)}>Queue for Delivery</Button>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={{ size: 9, offset: 3 }} className="mt-2">
              {
                isDelivering
                && <LoadingSpinner text="Delivering..." />
              }
            </Col>
          </FormGroup>
        </div>
      );
    }
  }

  return (
    <div>
      <p className="mb-3">
        <Link to="/admin/order">&larr; Back to Orders List</Link>
      </p>
      <h2 className="mb-4">
        Order #
        {orderId}
      </h2>
      {
        isLoading
        && <LoadingSpinner />
      }
      {
        errorMessage
        && <Alert color="danger" fade isOpen={errorMessage !== ''}>{errorMessage}</Alert>
      }
      {
        (order)
        && <Form>
          <FormGroup row>
            <Label sm={3}>Name</Label>
            <Col sm={9}>
              <Label className="text-muted">
                {order.firstName}
                {' '}
                {order.lastName}
              </Label>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Email</Label>
            <Col sm={9}>
              <Label className="text-muted">{order.email}</Label>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Address</Label>
            <Col sm={9}>
              <Label className="text-muted">{order.address}</Label>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Paid Date</Label>
            <Col sm={9}>
              <Label className="text-muted mr-3">
                {
                  (order.paidDateTime)
                    ? <span className="text-success">{new Date(order.paidDateTime).toLocaleDateString()}</span>
                    : <span className="text-danger">(Not yet)</span>
                }
                {' '}
                Quickbooks link(s):{' '}
                {
                  (order.quickbooksInvoiceUrl)
                  && <a href={cleanUrl(order.quickbooksInvoiceUrl)} target="_blank" rel='noreferrer'>Invoice</a>
                }
                {' '}
                <a href={cleanUrl(order.quickbooksCustomerUrl)} target="_blank" rel='noreferrer'>Customer</a>
              </Label>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Invoice PDF</Label>
            <Col sm={9}>
              {
                order.paidDateTime
                  ? <Button color="link" className="p-0" onClick={handleDownloadInvoicePdf}>Download Invoice PDF</Button>
                  : <Label className="text-muted">(Available after invoice paid)</Label>
              }
              {' '}
              {
                isGeneratingInvoicePdf
                && <LoadingSpinner text="Generating..." />
              }
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="virtualTourUrl" sm={3}>Virtual Tour URL</Label>
            <Col sm={9}>
              {
                alreadyDelivered
                  ? <a href={cleanUrl(order.virtualTourUrl)} target="_blank" rel='noreferrer'>{cleanUrl(order.virtualTourUrl)}</a>
                  : <Input type="url" name="url" id="virtualTourUrl" disabled={order.canEditOrder === false} value={order.virtualTourUrl} onChange={updateVirtualTourUrl} />
              }
            </Col>
          </FormGroup>
          {
            order.canViewOrderUrl &&
            <FormGroup row>
              <Label for="orderUrl" sm={3}>Order URL</Label>
              <Col sm={9}>
                {
                  alreadyDelivered ? <a href={cleanUrl(order.orderUrl)} target="_blank" rel='noreferrer'>{cleanUrl(order.orderUrl)}</a> : <Label className="text-muted">(Available after delivery)</Label>
                }

              </Col>
            </FormGroup>
          }
          <FormGroup row>
            <Label sm={3}>Photographer (WIP, doesn't save yet)</Label>
            <Col sm={3}>
              {
                // TODO: add something like onChange={e => setSelectedPhotographer(e.target.value)} AB#828
                isLoadingPhotographers ?
                  <LoadingSpinner /> :
                  <Input type="select" name="photographer" value={order.photographerId} disabled={order.canEditOrder === false}>
                    <option value={undefined} key={undefined} className="text-muted">(None)</option>
                    {
                      photographers.map(photographer =>
                        <option value={photographer.id} key={photographer.id}>{photographer.email}</option>
                      )
                    }
                  </Input>
              }
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="manageFiles" sm={3}>Manage Files</Label>
            <Col sm={9}>
              <FileBrowser orderId={Number.parseInt(orderId)} writeAccess />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="deliveryNotes" sm={3}>Notes for Delivery Email</Label>
            <Col sm={9}>
              <Input type="textarea" name="text" id="deliveryNotes" disabled={alreadyDelivered || (order.canEditOrder === false)} value={order.deliveryNotes} onChange={updateDeliveryNotes} />
            </Col>
          </FormGroup>
          {deliverySection}
        </Form>
      }
    </div >
  );
}
