import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { Document, Page, pdfjs } from 'react-pdf';

// support for browserify per https://github.com/wojtekmaj/react-pdf#standard-browserify-and-others
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PreviewProps {
  previewUrl: string,
  isOpen: boolean,
  isPdf: boolean;
  title: string,
  imageIndex: number,
  imageTotalCount: number,
  onIndexChange: (newIndex: number) => void,
  onToggle: () => void
}

export function Preview(props: PreviewProps) {

  const PDF_PREVIEW_WIDTH = 300;

  const changeIndex = (newIndex: number) => {
    if(newIndex < 0 || newIndex >= props.imageTotalCount)
      return;

    props.onIndexChange(newIndex);
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.onToggle} backdrop={true} keyboard={true}>
      <ModalHeader>
        {props.title}
      </ModalHeader>
      <ModalBody>
        {
          props.isPdf ?
          <div className="d-flex justify-content-center">
            <Document file={props.previewUrl}>
              <Page pageNumber={1} width={PDF_PREVIEW_WIDTH} />
            </Document>
          </div> :
          <img src={props.previewUrl} alt={props.title} className="img-fluid rounded" />
        }
        <div className="text-muted mx-2">(Image {props.imageIndex+1} of {props.imageTotalCount})</div>
      </ModalBody>
      <ModalFooter className="mx-auto">
        <FontAwesomeIcon icon={faChevronLeft} onClick={() => changeIndex(props.imageIndex-1)} className="text-muted" size="lg" />
        <FontAwesomeIcon icon={faChevronRight} onClick={() => changeIndex(props.imageIndex+1)} className="text-muted" size="lg" />
      </ModalFooter>
    </Modal>
  );
}
