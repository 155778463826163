export function removeDashes(input: string) {
  const regex = /[-–—]/g;
  return input.replace(regex, '');
}

export function removeSlashesAndBackslashes(input: string) {
  const regex = /[\\\/]/g;
  return input.replace(regex, '');
}

export function removeAllWhitespace(input: string) {
  const allWhitespaceRegex = /\s/g;
  return input.replace(allWhitespaceRegex, '');
}
