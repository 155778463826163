import React, { useEffect, useState } from 'react';
import { Alert, Button, Table } from 'reactstrap';
import { CreateFolder } from './CreateFolder';
import { fileDownload } from './fileDownload';
import { FileRow } from './FileRow';
import { FolderRow } from './FolderRow';
import { LoadingSpinner } from '../LoadingSpinner';
import { OnIconClickEvent } from './OnIconClickEvent';
import { Preview } from './Preview';
import { UpRow } from './UpRow';
import { Uploader } from './Uploader';
import validateFetchResponse from '../../validateFetchResponse';
import authService from '../Api-Authorization/AuthorizeService';
import { useCookies } from 'react-cookie';

interface FileBrowserProps {
  orderId: number,
  orderGuid?: string,
  writeAccess: boolean
}

interface DirectoryOrFile {
  name: string,
  previewFileName: string,
  fileSize?: number,
  isDirectory: boolean
}

export function FileBrowser(props: FileBrowserProps) {
  const apiBaseUrl: string = props.writeAccess ? `api/adminorder/${props.orderId}` : `api/order/${props.orderGuid}`;

  const [cookies] = useCookies(['csrfRequestToken']);
  const csrfRequestToken: string = cookies.csrfRequestToken;

  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(true);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [files, setFiles] = useState<Array<DirectoryOrFile>>([]);
  const [relativePath, setRelativePath] = useState<string>('');
  const [relativePathToLoad, setRelativePathToLoad] = useState<string>('');
  const [refreshCount, setRefreshCount] = useState<number>(0);

  const [previewIndex, setPreviewIndex] = useState<number>(0);
  const [previewableImageCount, setPreviewableImageCount] = useState<number>(0);

  const [previewIsPdf, setPreviewIsPdf] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [previewTitle, setPreviewTitle] = useState<string>('');

  const [createFolderPopupVisible, setCreateFolderPopupVisible] = useState<boolean>(false);
  const toggleCreatePopupFolderVisible = () => setCreateFolderPopupVisible(!createFolderPopupVisible);

  const [previewPopupVisible, setPreviewPopupVisible] = useState<boolean>(false);
  const togglePreview = () => setPreviewPopupVisible(!previewPopupVisible);

  const [authToken, setAuthToken] = useState<string | null>(null);

  const getUniqueKey = (directoryOrFile: DirectoryOrFile) => {
    let key = directoryOrFile.isDirectory ? 'directory: ' : 'file: ';
    key += relativePath.length === 0 ? relativePath : `${relativePath}/`;
    key += directoryOrFile.name;
    return key;
  };

  const handleCreateFolder = (folderName: string) => {
    setCreateFolderPopupVisible(false);
    setStatusMessage(`Creating folder ${folderName}...`);
    setIsLoadingFile(true);

    fetch(
      `${apiBaseUrl}/files/createfolder`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          relativePath,
          folderName,
          "X-CSRF-REQUEST-TOKEN": csrfRequestToken,
        },
      },
    )
      .then(validateFetchResponse)
      .then(() => setStatusMessage(`Created folder ${folderName}`))
      .then(() => setRefreshCount(refreshCount + 1))
      .catch((error: Error) => { setStatusMessage(''); setErrorMessage(error.message); })
      .finally(() => setIsLoadingFile(false));
  };

  const handleDownloadClick: OnIconClickEvent = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, name: string) => {
    e.preventDefault();

    setIsLoadingFile(true);
    setStatusMessage(`Downloading ${name}...`);

    fetch(
      `${apiBaseUrl}/files/download`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          relativePath,
          fileName: name,
          preview: 'false',
          "X-CSRF-REQUEST-TOKEN": csrfRequestToken,
        },
      },
    )
      .then(validateFetchResponse)
      .then((response) => response.blob())
      .then((blob) => fileDownload(blob, name))
      .then(() => setStatusMessage(''))
      .catch((error: Error) => { setStatusMessage(''); setErrorMessage(error.message); })
      .finally(() => setIsLoadingFile(false));
  };

  const handleDeleteFileClick: OnIconClickEvent = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, fileName: string) => {
    e.preventDefault();
    setIsLoadingFile(true);

    fetch(
      `${apiBaseUrl}/files/delete`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          relativePath,
          fileName,
          "X-CSRF-REQUEST-TOKEN": csrfRequestToken,
        },
      },
    )
      .then(validateFetchResponse)
      .then(() => setStatusMessage(`Deleted ${fileName}.`))
      .catch((error: Error) => { setStatusMessage(''); setErrorMessage(error.message); })
      .finally(() => {
        setRefreshCount(refreshCount + 1);
        setIsLoadingFile(false);
      });
  };

  const handleDeleteFolderClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, folderName: string) => {
    e.preventDefault();
    setIsLoadingFile(true);

    const pathPrefix = (relativePath === '') ? '' : `${relativePath}/`;
    const pathToDelete = `${pathPrefix}${folderName}`;

    fetch(
      `${apiBaseUrl}/files/delete`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          relativePath: pathToDelete,
          "X-CSRF-REQUEST-TOKEN": csrfRequestToken,
        },
      },
    )
      .then(validateFetchResponse)
      .then(() => setStatusMessage(`Deleted ${pathToDelete}.`))
      .catch((error: Error) => { setStatusMessage(''); setErrorMessage(error.message); })
      .finally(() => {
        setRefreshCount(refreshCount + 1);
        setIsLoadingFile(false);
      });

    setStatusMessage(`Deleted ${pathToDelete}.`);
  };

  const handleDownloadZipClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    setIsLoadingFile(true);
    setStatusMessage('Downloading...');

    fetch(
      `${apiBaseUrl}/files/zip`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRF-REQUEST-TOKEN": csrfRequestToken,
        },
      },
    )
      .then(validateFetchResponse)
      .then((response) => response.blob())
      .then((blob) => fileDownload(blob, `BFP Order ${props.orderId}.zip`))
      .then(() => setStatusMessage(''))
      .catch((error: Error) => { setStatusMessage(''); setErrorMessage(error.message); })
      .finally(() => setIsLoadingFile(false));
  };

  const handlePathChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, newPath: string) => {
    e.preventDefault();

    // console.log(`handlePathChange: updating to '${newPath}'`);
    setRelativePathToLoad(newPath);
    setErrorMessage('');
    setStatusMessage('');
  };

  const handleUpClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();

    const index = relativePath.lastIndexOf('/');
    if (index < 0) {
      return;
    }

    const newPath = relativePath.substring(0, index);
    // console.log(`up clicked, new path: '${newPath}'`);
    handlePathChange(e, newPath);
  };

  const handleFolderClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, name: string) => {
    e.preventDefault();
    const newPath = `${relativePath}/${name}`;
    // console.log(`folder clicked: '${name}', new path: '${newPath}'`);
    handlePathChange(e, newPath);
  };

  const getPreviewableFiles = () => files.filter((file) => (file.previewFileName !== ''));

  const updatePreview = (newIndex: number) => {
    const files = getPreviewableFiles();
    setPreviewableImageCount(files.length);
    setIsLoadingFile(true);

    const fileToPreview = files[newIndex];
    const isPdf = fileToPreview.previewFileName.toLowerCase().endsWith('.pdf');

    fetch(
      `${apiBaseUrl}/files/download`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          relativePath,
          fileName: fileToPreview.previewFileName,
          preview: 'true',
          "X-CSRF-REQUEST-TOKEN": csrfRequestToken,
        },
      },
    )
      .then(validateFetchResponse)
      .then((response) => response.blob())
      .then((blob) => {
        if (previewUrl) {
          URL.revokeObjectURL(previewUrl);
        }
        setPreviewUrl(URL.createObjectURL(blob));
        setPreviewTitle(fileToPreview.name);
        setPreviewIsPdf(isPdf);
        setPreviewIndex(newIndex);
        setPreviewPopupVisible(true);
      })
      .catch((error: Error) => { setStatusMessage(''); setErrorMessage(error.message); })
      .finally(() => setIsLoadingFile(false));
  };

  const handlePreviewClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, name: string) => {
    e.preventDefault();

    const files = getPreviewableFiles();
    const newIndex = files.findIndex((file) => file.name === name);
    // console.log(`handlePreviewClick: newIndex = ${newIndex}`);

    if (newIndex < 0) return;

    updatePreview(newIndex);
  };

  useEffect(() => {
    if (props.writeAccess === false) {
      return;
    }

    const getToken = async () => {
      const newToken = await authService.getAccessToken();
      setAuthToken(newToken);
    };

    getToken();
  }, []);

  useEffect(() => {
    const waitingForAuthToken: boolean = props.writeAccess && (!authToken);
    if (waitingForAuthToken) return;

    setIsLoadingFile(true);

    fetch(
      `${apiBaseUrl}/files`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          relativePath: relativePathToLoad,
          "X-CSRF-REQUEST-TOKEN": csrfRequestToken,
        },
      },
    )
      .then(validateFetchResponse)
      .then((response) => response.json())
      .then((json) => {
        setRelativePath(relativePathToLoad);
        setFiles(json);
      })
      .catch((error: Error) => { setStatusMessage(''); setErrorMessage(error.message); })
      .finally(() => {
        setIsLoadingPage(false);
        setIsLoadingFile(false);
      });
  }, [relativePathToLoad, refreshCount, authToken]);

  let fileFolderRows: Array<JSX.Element> = files.map((directoryOrFile: DirectoryOrFile) => (directoryOrFile.isDirectory
    ? <FolderRow name={directoryOrFile.name} key={getUniqueKey(directoryOrFile)} writeAccess={props.writeAccess} onRowClick={handleFolderClick} onDeleteClick={handleDeleteFolderClick} />
    : <FileRow name={directoryOrFile.name} key={getUniqueKey(directoryOrFile)} hasThumbnail={directoryOrFile.previewFileName !== ''} writeAccess={props.writeAccess} onRowClick={handlePreviewClick} size={directoryOrFile.fileSize} onDeleteClick={handleDeleteFileClick} onDownloadClick={handleDownloadClick} />));

  if (relativePath !== '') {
    fileFolderRows = [<UpRow key="upRow" onClick={handleUpClick} />].concat(fileFolderRows);
  }

  fileFolderRows.push(
    <tr key="commandsRow" className="d-flex">
      <td className="col-1" />
      <td className="col-9">
        {
          props.writeAccess
          && <Button color="link" onClick={toggleCreatePopupFolderVisible}>Add New Folder</Button>
        }
        <CreateFolder isOpen={createFolderPopupVisible} onToggle={toggleCreatePopupFolderVisible} onCreateConfirmed={handleCreateFolder} />
      </td>
      <td className="col-2 text-right">
        {
          (files.length > 0)
          && <Button color="link" onClick={handleDownloadZipClick}>Download All</Button>
        }
      </td>
    </tr>,
  );
  if (files.length === 0) {
    fileFolderRows.push(
      <tr key="noFilesRow" className="d-flex">
        <td className="col-1" />
        <td className="col-6 text-muted">(No files found)</td>
        <td className="col-5" />
      </tr>,
    );
  }

  return (
    <div>
      {
        isLoadingPage
        && <LoadingSpinner />
      }
      {
        statusMessage
        && <Alert color="success" fade isOpen={statusMessage !== ''}>{statusMessage}</Alert>
      }
      {
        errorMessage
        && <Alert color="danger" fade isOpen={errorMessage !== ''}>{errorMessage}</Alert>
      }
      {
        (!isLoadingPage)
        && (
          <Table borderless hover size="sm">
            <thead>
              <tr className="d-flex">
                <th className="col-1" />
                <th className="col-6 text-muted">Name</th>
                <th className="col-3 text-muted">Size</th>
                <th className="col-2 text-muted text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                fileFolderRows
              }
            </tbody>
          </Table>
        )
      }
      {
        (isLoadingFile && !isLoadingPage)
        && <LoadingSpinner />
      }
      {
        props.writeAccess
        && <Uploader orderId={props.orderId} relativePath={relativePath} onError={setErrorMessage} onStatusChange={setStatusMessage} onUploaded={() => setRefreshCount(refreshCount + 1)} />
      }
      <Preview title={previewTitle} isOpen={previewPopupVisible} isPdf={previewIsPdf} imageIndex={previewIndex} imageTotalCount={previewableImageCount} previewUrl={previewUrl} onToggle={togglePreview} onIndexChange={updatePreview} />
    </div>
  );
}
