import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export type OnPageChangeEvent = (target: React.MouseEvent<HTMLAnchorElement>, pageIndex: number) => void;

interface TablePagingProps{
  pagesCount: number,
  currentPageIndex: number,
  onPageChange: OnPageChangeEvent
}

export function TablePaging(props: TablePagingProps) {
  function goToPage(e: React.MouseEvent<HTMLAnchorElement>, pageIndex: number) {
    let actualPageIndex = props.currentPageIndex;

    if (pageIndex >= 0 && pageIndex < props.pagesCount) {
      actualPageIndex = pageIndex;
    }
    props.onPageChange(e, actualPageIndex);
  }

  const pages: Array<JSX.Element> = [];

  for (let pageIndex: number = 0; pageIndex < props.pagesCount; pageIndex++) {
    pages.push(
      <PaginationItem active={pageIndex === props.currentPageIndex} key={pageIndex}>
        <PaginationLink onClick={(e) => goToPage(e, pageIndex)} href="#">{pageIndex + 1}</PaginationLink>
      </PaginationItem>,
    );
  }

  return (
    <div>
      {
        props.pagesCount > 0
        && (
        <Pagination aria-label="Table paging">
          <PaginationItem>
            <PaginationLink first onClick={(e) => goToPage(e, 0)} href="#" />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink previous onClick={(e) => goToPage(e, props.currentPageIndex - 1)} href="#" />
          </PaginationItem>
          {pages}
          <PaginationItem>
            <PaginationLink next onClick={(e) => goToPage(e, props.currentPageIndex + 1)} href="#" />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink last onClick={(e) => goToPage(e, props.pagesCount - 1)} href="#" />
          </PaginationItem>
        </Pagination>
        )
      }
    </div>
  );
}
