import React from 'react';

const NotFound = () => (
  <div>
    <h1>
      Not Found
    </h1>
    <div>
      Sorry! We couldn't find the resource you are looking for.
    </div>
  </div>
);

export { NotFound };
