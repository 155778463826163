import React from 'react';
import { faLevelUpAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type OnUpClickEvent = (
  target: React.MouseEvent<HTMLDivElement, MouseEvent>
) => void;

interface UpRowProps{
  onClick: OnUpClickEvent
}

export function UpRow(props: UpRowProps) {

  function upClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>){
    e.preventDefault();
    props.onClick(e);
  }

  return(
    <tr className="d-flex" onClick={upClick}>
      <td className="col-1"><FontAwesomeIcon icon={faLevelUpAlt} className="text-muted" /></td>
      <td className="col-8">..</td>
      <td className="col-3"></td>
    </tr>
  );
}