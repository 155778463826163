import React from 'react';
import { Spinner } from 'reactstrap';

interface LoadingSpinnerProps{
  text?: string
}

export function LoadingSpinner(props: LoadingSpinnerProps) {
  const displayText: string = props.text ?? 'Loading...';

  return (
    <div className="ml-3 mb-2">
      <Spinner color="success" size="sm" />
      {' '}
      <span className="text-muted">{displayText}</span>
    </div>
  );
}
