import React, { useState } from 'react';
import { faFolderOpen, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteConfirmation from './DeleteConfirmation';
import { OnIconClickEvent } from './OnIconClickEvent';
import { OnRowClickEvent } from './OnRowClickEvent';

interface FolderRowProps {
  name: string,
  writeAccess: boolean,
  onDeleteClick: OnIconClickEvent,
  onRowClick: OnRowClickEvent
}

export function FolderRow(props: FolderRowProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const toggleModal = () => setModalVisible(!modalVisible);

  const handleDelete = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setModalVisible(false);
    props.onDeleteClick(e, props.name);
  };

  function rowClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    // TODO: find if trash can?
    // if(e.target instanceof SVGSVGElement || e.target instanceof SVGPathElement){
    //   let test = (SVGSVGElement)e.target;
    //   console.log(`FolderRow click target.getElementById: ${test.getElementById("DeleteFolder")}`);
    // }

    const iconClickedSoShouldIgnore = (e.target instanceof SVGElement);
    if (iconClickedSoShouldIgnore) return;

    e.preventDefault();
    props.onRowClick(e, props.name);
  }

  return (
    <tr className="d-flex" onClick={rowClick}>
      <td className="col-1"><FontAwesomeIcon icon={faFolderOpen} className="text-muted" /></td>
      <td className="col-6">{props.name}</td>
      <td className="col-3" />
      <td className="col-2 text-right">
        {
          props.writeAccess
          && (
          <span>
            <FontAwesomeIcon id="DeleteIcon" icon={faTrashAlt} onClick={toggleModal} className="text-muted" size="lg" />
            <DeleteConfirmation isOpen={modalVisible} name={props.name} onDeleteClick={handleDelete} onToggle={toggleModal} />
          </span>
          )
        }
      </td>
    </tr>
  );
}
