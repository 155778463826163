import React, { useEffect, useState } from 'react';
import Cards, { Focused } from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { Redirect } from 'react-router-dom';
import {
  Alert, Button, Col, Form, FormGroup, Input, Label
} from 'reactstrap';
import ConfirmationModal from './ConfirmationModal';
import { removeSlashesAndBackslashes, removeAllWhitespace } from '../utilities/InputCleaner';
import validateFetchResponse from '../validateFetchResponse';
import { LoadingSpinner } from './LoadingSpinner';
import { useCookies } from 'react-cookie';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ReCaptcha from './ReCaptcha';
import { PaymentStatus } from './Enums/PaymentStatus';

interface CreditCardFormProps {
  amount: number,
  customerGuid: string,
  intuitPaymentsBaseUrl: string,
  orderGuid: string
}

interface CreditCardOnFile {
  id: string,
  isDefault: boolean,
  numberWithLastFourDigitsOnly: number
}

interface CreditCardTokenFromIntuit {
  value: string
}

export default function CreditCardForm(props: CreditCardFormProps) {

  const [cookies] = useCookies(['csrfRequestToken']);
  const csrfRequestToken: string = cookies.csrfRequestToken;

  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(PaymentStatus.PageLoaded);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingCardsOnFile, setIsLoadingCardsOnFile] = useState<boolean>(true);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const NEW_CARD: string = "NEW_CARD";
  const [cardsOnFile, setCardsOnFile] = useState<Array<CreditCardOnFile>>([]);
  const [selectedCardOnFileId, setSelectedCardOnFileId] = useState<string>(NEW_CARD);
  const [defaultCardPreselectComplete, setDefaultCardPreselectComplete] = useState<boolean>(false);

  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [saveCardOnFile, setSaveCardOnFile] = useState<boolean>(true);

  const [isCallingRecaptcha, setisCallingRecaptcha] = useState<boolean>(false);
  const [reCaptchaToken, setReCaptchaToken] = useState('');

  const [focus, setFocus] = useState<Focused>('name');

  const [modalVisible, setModalVisible] = useState(false);
  const toggleModal = () => setModalVisible(!modalVisible);

  const handleFocusChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setFocus(event.target.name as Focused);
  };

  const moneyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });

  //const { cardsOnFile, isLoadingCardsOnFile, cardsOnFileError } = useFetch<Array<CreditCardOnFile>>(`api/customer/${props.customerGuid}/cards`);

  useEffect(() => {

    if (!isLoadingCardsOnFile) {
      return;
    }

    const getCardsOnFile = async () => {
      fetch(`api/customer/${props.customerGuid}/cards`,
        {
          method: 'GET',
          headers: { 'X-CSRF-REQUEST-TOKEN': csrfRequestToken },
        }
      )
        .then(validateFetchResponse)
        .then((response) => response.json())
        .then((json) => {
          setCardsOnFile(json);
          setIsLoadingCardsOnFile(false);
        })
        .catch((error: Error) => {
          console.error(error.message)

          setStatusMessage('');
          setErrorMessage('Failed to retrieve cards on file.');
          setIsLoadingCardsOnFile(false);
        });
    };

    getCardsOnFile();
  }, [props.customerGuid, isLoadingCardsOnFile]);

  useEffect(() => {

    const preselectDefaultCardOnInitialLoad = () => {
      if (isLoadingCardsOnFile || defaultCardPreselectComplete) {
        return;
      }
      setDefaultCardPreselectComplete(true);

      //console.log(`preselectDefaultCardOnInitialLoad: attempting to pre-select a default card`);

      if (selectedCardOnFileId !== NEW_CARD) {
        return;
      }

      let defaultCard = cardsOnFile.find(card => card.isDefault);
      if (!defaultCard) {
        //console.log(`preselectDefaultCardOnInitialLoad: no default, looking for a fallback..`);
        defaultCard = cardsOnFile.find(card => card.id !== NEW_CARD);
      }

      if (defaultCard) {
        //console.log(`preselectDefaultCardOnInitialLoad: preselected card with ID ${defaultCard.id}`);
        setSelectedCardOnFileId(defaultCard.id);
      }
    };

    preselectDefaultCardOnInitialLoad();

  }, [cardsOnFile, defaultCardPreselectComplete, isLoadingCardsOnFile, selectedCardOnFileId])

  useEffect(() => {

    const clearErrorAndStatusIfCardSelectionChanges = () => {
      if (!defaultCardPreselectComplete) {
        return;
      }

      setErrorMessage('');
      setStatusMessage('');
    };

    clearErrorAndStatusIfCardSelectionChanges();

  }, [defaultCardPreselectComplete, selectedCardOnFileId]);

  const getCreditCardToken = async (): Promise<CreditCardTokenFromIntuit | null> => {
    const cleanedMonth = expiry.substring(0, 2);
    let cleanedYear = expiry.substring(2);
    cleanedYear = removeSlashesAndBackslashes(cleanedYear).trim();
    cleanedYear = cleanedYear.length === 2 ? `20${cleanedYear}` : cleanedYear;

    const cleanedName = name.trim();
    const cleanedNumber = removeAllWhitespace(number);
    const cleanedCVC = cvc.trim();

    const cardData = {
      card: {
        name: cleanedName,
        number: cleanedNumber,
        expMonth: cleanedMonth,
        expYear: cleanedYear,
        cvc: cleanedCVC,
      },
    };

    return fetch(`${props.intuitPaymentsBaseUrl}quickbooks/v4/payments/tokens`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(cardData),
      })
      .then(validateFetchResponse)
      .then((response) => response.json())
      .then((json) => {
        const token: CreditCardTokenFromIntuit = { value: json.value };
        //console.log(`returned token ${token.value}`);
        return token;
      })
      .catch((error: Error) => {
        console.error(error.message);
        setErrorMessage('Invalid credit card. Is the above information correct?');
        setStatusMessage('');

        return null;
      });
  };

  const deleteCardOnFile = async (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.preventDefault();
    setModalVisible(false);

    fetch(
      `api/customer/${props.customerGuid}/cards/${selectedCardOnFileId}`,
      {
        method: 'DELETE',
        headers: { 'X-CSRF-REQUEST-TOKEN': csrfRequestToken },
      },
    )
      .then(validateFetchResponse)
      .then(() => {

        setStatusMessage('Card removed.');
        setErrorMessage('');

        setSelectedCardOnFileId(NEW_CARD);
        setIsLoadingCardsOnFile(true);
        setDefaultCardPreselectComplete(false);
      })
      .catch((error: Error) => {

        console.error(error.message);

        setStatusMessage('');
        setErrorMessage('Failed to delete card.');
        setIsLoading(false);
      });
  }

  const PayNowWithNewCard = async (forceDoNotSaveOnFile: boolean) => {
    setIsLoading(true);

    const creditCardToken = await getCreditCardToken();
    if (creditCardToken === null) {
      setIsLoading(false);
      return;
    }

    let saveCardOnFileValue: string;
    if (forceDoNotSaveOnFile) {
      saveCardOnFileValue = "false";
    }
    else {
      saveCardOnFileValue = saveCardOnFile ? "true" : "false";
    }

    fetch(
      `api/order/${props.orderGuid}/cardpayment`,
      {
        method: 'POST',
        headers: {
          amount: props.amount.toString(),
          creditCardToken: creditCardToken.value,
          reCaptchaToken: reCaptchaToken,
          saveCardOnFile: saveCardOnFileValue,
          'X-CSRF-REQUEST-TOKEN': csrfRequestToken,
        },
      },
    )
      .then(validateFetchResponse)
      .then(() => {
        setStatusMessage('Successfully paid! Redirecting to order...');
        setPaymentStatus(PaymentStatus.SuccessfullyPaid);
      })
      .catch((error: Error) => {

        if (shouldTryAgainBecauseCardWasAlreadySavedOnFile(forceDoNotSaveOnFile, error)) {
          return PayNowWithNewCard(true);
        }

        console.error(error.message);

        setStatusMessage('');
        setErrorMessage('Payment failed. Is credit card info correct?');
        setIsLoading(false);
      });
  }

  const PayNowWithCardOnFile = async () => {
    setIsLoading(true);

    fetch(
      `api/order/${props.orderGuid}/cardonfilepayment`,
      {
        method: 'POST',
        headers: {
          amount: props.amount.toString(),
          quickbooksCardId: selectedCardOnFileId,
          reCaptchaToken: reCaptchaToken,
          'X-CSRF-REQUEST-TOKEN': csrfRequestToken,
        },
      },
    )
      .then(validateFetchResponse)
      .then(() => {
        setStatusMessage('Successfully paid! Redirecting to order...');
        setPaymentStatus(PaymentStatus.SuccessfullyPaid);
      })
      .catch((error: Error) => {

        console.error(error.message);

        setStatusMessage('');
        setErrorMessage('Payment failed. Is credit card still valid?');
        setIsLoading(false);
      });
  }

  useEffect(() => {

    //console.log(`paymentStatus: ${paymentStatus}`);

    if (paymentStatus === PaymentStatus.PayWasClicked) {
      setisCallingRecaptcha(true);
      setPaymentStatus(PaymentStatus.WaitingOnRecaptcha);
      return;
    }

    if (paymentStatus === PaymentStatus.WaitingOnRecaptcha) {
      //console.log("waiting on reCAPTCHA...");
      if (isCallingRecaptcha === false && reCaptchaToken) {
        //console.log("we have a reCAPTCHA token, paying!");

        if (selectedCardOnFileId === NEW_CARD) {
          PayNowWithNewCard(false);
        }
        else{
          PayNowWithCardOnFile();
        }

        setPaymentStatus(PaymentStatus.WaitingOnPaymentProcessing);
      }
      return;
    }

  }, [isCallingRecaptcha, paymentStatus, reCaptchaToken]);


  const shouldTryAgainBecauseCardWasAlreadySavedOnFile = (forceDoNotSaveOnFile: boolean, error: Error) => {
    return saveCardOnFile && (forceDoNotSaveOnFile === false) && (error.message.indexOf("409 Conflict") > -1);
  }

  if (paymentStatus === PaymentStatus.SuccessfullyPaid) {
    const orderUrl = `/order/${props.orderGuid}`;
    return <Redirect to={orderUrl} />;
  }

  // console.log(`selectedCardOnFileId: ${selectedCardOnFileId}`);

  return (
    <div>
      <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setPaymentStatus(PaymentStatus.PayWasClicked);
      }}>
        <FormGroup row className="ml-0">
          <Label sm={4} className="text-nowrap">Select Card</Label>
          <Col sm={8}>
            <Input type="select" name="card" value={selectedCardOnFileId} onChange={e => setSelectedCardOnFileId(e.target.value)} onFocus={handleFocusChange}>
              <option value={NEW_CARD} key={NEW_CARD}>New Card</option>
              {
                cardsOnFile.map(card =>
                  <option value={card.id} key={card.id}>{card.numberWithLastFourDigitsOnly}</option>
                )
              }
            </Input>
          </Col>
        </FormGroup>
        {
          (selectedCardOnFileId !== NEW_CARD) &&
          <FormGroup row className="ml-0">
            <Label sm={4}></Label>
            <Col sm={8}>
              <Button color="link" className="text-nowrap" size="sm" onClick={toggleModal}>
                Forget this card
              </Button>
              <ConfirmationModal isOpen={modalVisible} confirmationText={"Forget card"} question={"Are you sure you'd like to remove this card?"} onConfirmClick={deleteCardOnFile} onToggle={toggleModal} />
            </Col>
          </FormGroup>
        }
        {
          (defaultCardPreselectComplete && (selectedCardOnFileId === NEW_CARD)) &&
          <div>
            <FormGroup row>
              <Cards
                cvc={cvc}
                focused={focus}
                name={name}
                number={number}
                expiry={expiry}
              />
            </FormGroup>
            <FormGroup row className="ml-0">
              <Label sm={4} for="frmNumber">Card Number</Label>
              <Col sm={8}>
                <Input
                  id="frmNumber"
                  type="tel"
                  name="number"
                  inputMode="numeric"
                  required
                  pattern="[0-9\s]{13,19}"
                  autoComplete="cc-number"
                  maxLength={19}
                  placeholder="xxxx xxxx xxxx xxxx"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  onFocus={handleFocusChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="ml-0">
              <Label sm={4} for="frmName">Name</Label>
              <Col sm={8}>
                <Input
                  id="frmName"
                  type="text"
                  name="ccname"
                  required
                  placeholder="Name"
                  autoComplete="cc-name"
                  maxLength={30}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onFocus={handleFocusChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="ml-0">
              <Label sm={4} for="frmExpiration">Expiration</Label>
              <Col sm={8}>
                <Input
                  id="frmExpiration"
                  type="text"
                  name="exp-date"
                  required
                  pattern="[0-9]{2}\/?[0-9]{2}"
                  placeholder="MM/YY"
                  autoComplete="cc-exp"
                  value={expiry}
                  onChange={(e) => setExpiry(e.target.value)}
                  onFocus={handleFocusChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="ml-0">
              <Label sm={4}>CVV code</Label>
              <Col sm={8}>
                <Input
                  type="tel"
                  name="cvc"
                  required
                  placeholder="CVV"
                  value={cvc}
                  onChange={(e) => setCvc(e.target.value)}
                  onFocus={handleFocusChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="ml-0">
              <Col className="ml-4">
                <Label check>
                  <Input
                    type="checkbox"
                    name="saveCardOnFile"
                    checked={saveCardOnFile}
                    onChange={(e) => setSaveCardOnFile(e.target.checked)}
                    onFocus={handleFocusChange}
                  />{' '}
                  Remember this card for next time
                </Label>
              </Col>
            </FormGroup>
          </div>
        }
        {
          (isLoading || isLoadingCardsOnFile)
          && (
            <FormGroup row>
              <LoadingSpinner />
            </FormGroup>
          )
        }
        {
          statusMessage !== '' && (
            <FormGroup row>
              <Alert color="success" fade isOpen={statusMessage !== ''}>{statusMessage}</Alert>
            </FormGroup>
          )
        }
        {
          errorMessage !== '' && (
            <FormGroup row>
              <Alert color="danger" fade isOpen={errorMessage !== ''}>{errorMessage}</Alert>
            </FormGroup>
          )
        }
        <FormGroup row className="mb-3">
          <Col>
            <Button type="submit" color="primary">
              {`Pay ${moneyFormatter.format(props.amount)}`}
            </Button>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col>
            <Label className="text-muted" size="sm">Payments are processed by <a href="https://quickbooks.intuit.com/payments/" target="_blank" rel='noreferrer'>Intuit Payments, Inc</a>.</Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col>
            <GoogleReCaptchaProvider reCaptchaKey="6LdbPjQhAAAAACSNl_LdLJsc9PmQU0fyicrRLbmz">
              <ReCaptcha nameOfUserAction={'payWithCreditCard'} isCallingReCaptcha={isCallingRecaptcha} onVerified={function (token: string): void {
                setReCaptchaToken(token);
                setisCallingRecaptcha(false);
              }} />
            </GoogleReCaptchaProvider>
          </Col>
        </FormGroup>
      </Form>
    </div>
  );
}

