import { useEffect, useRef, useState } from 'react';
import validateFetchResponse from '../validateFetchResponse';

interface Cache<T> {
  [url: string]: T
}

interface FetchResult<T> {
  data?: T;
  isLoading: boolean;
  error?: string;
}

const getFetchOptionsWithAbortSupport = (signal: AbortSignal, initialOptions?: RequestInit) => {
  let options: RequestInit = {};
  if (initialOptions) {
    options = initialOptions;
  }
  options.signal = signal;

  return options;
};

export default function useFetch<T>(url: string, options?: RequestInit): FetchResult<T> {
  const cache = useRef<Cache<T>>({});

  const [data, setData] = useState<T>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<undefined|string>();

  useEffect(() => {
    const cleanUpFunction = () => {
      console.log(`cleaning up: ${url}`);
      abortController.abort();
    };

    if (!url) return cleanUpFunction;

    const abortController = new AbortController();
    const { signal } = abortController;

    const doFetch = async () => {
      setIsLoading(true);

      if (cache.current[url]) {
        console.log(`found in cache: ${url}`);
        setData(cache.current[url]);
        setError(undefined);
        setIsLoading(false);
        return;
      }

      try {
        const optionsWithAbortSupport = getFetchOptionsWithAbortSupport(abortController.signal, options);

        console.log(`fetching: ${url}`);
        const response = await fetch(url, optionsWithAbortSupport);

        validateFetchResponse(response);

        const json = await response.json();

        if (signal.aborted) {
          // console.log(`aborting before returning data: ${url}`);
          return;
        }

        console.log(`returning data: ${url}`);

        cache.current[url] = json;
        setData(json);
        setError(undefined);
      } catch (newError) {
        if (signal.aborted) {
          // console.log(`aborting before returning error: ${url}`);
          return;
        }

        setData(undefined);
        if (newError instanceof Error) {
            setError(newError.message);
        }
        else if (typeof newError === "string") {
            setError(newError);
        }
        
      } finally {
        setIsLoading(false);
      }
    };

    doFetch();

    return cleanUpFunction;
  }, [url]);

  return { data, isLoading, error };
}
