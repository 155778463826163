import React, { useState, useEffect } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { Alert, Button, Col, Form, FormGroup, Label } from 'reactstrap';
import cleanUrl from '../utilities/cleanUrl';
import validateFetchResponse from '../validateFetchResponse';
import { OrderViewDto } from './Models/OrderDto';
import { LoadingSpinner } from './LoadingSpinner';
import { NotFound } from './NotFound';
import { FileBrowser } from './FileBrowser/FileBrowser';
import { fileDownload } from './FileBrowser/fileDownload';
import { useCookies } from 'react-cookie';

interface OrderProps {
  guid: string;
}

export function Order() {
  const { guid } = useParams<OrderProps>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isGeneratingInvoicePdf, setIsGeneratingInvoicePdf] = useState<boolean>(false);
  const [isNotFound, setIsNotFound] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [order, setOrder] = useState<OrderViewDto>();

  useEffect(() => {
    const getOrder = async () => {

      setIsLoading(true);

      fetch(`api/order/${guid}`, { method: 'GET' })
        .then(validateFetchResponse)
        .then((response) => response.json())
        .then((json) => {
          setOrder(json);
          setIsLoading(false);
          setErrorMessage('');
        })
        .catch((error: Error) => {
          setErrorMessage(error.message);
          setIsNotFound(true);
        });
    };

    getOrder();
  }, []);

  const handleDownloadInvoicePdf = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

    e.preventDefault();

    if (!order) {
      return;
    }

    setIsGeneratingInvoicePdf(true);

    fetch(`api/order/${guid}/invoicepdf`)
      .then(validateFetchResponse)
      .then((response) => response.blob())
      .then((blob) => fileDownload(blob, `Invoice BFP${order.id}.pdf`))
      .catch((error: Error) => setErrorMessage(error.message))
      .finally(() => setIsGeneratingInvoicePdf(false));
  };


  if (isNotFound) {
    return <NotFound />;
  }

  if (order) {
    const notAllowedToViewOrder: boolean = !(order.paidDateTime) && !(order.allowAccessBeforePayment);
    if (notAllowedToViewOrder) {
      const paymentUrl = `/order/${guid}/payment`;
      return <Redirect to={paymentUrl} />;
    }
  }

  return (
    <div>
      {
        isLoading
        && <LoadingSpinner />
      }
      {
        (order)
        && (
          <div>
            <p className="mb-3">
              <Link to={`/customer/${order.customerGuid}`}>&larr; View all my orders</Link>
            </p>
            <h2 className="mb-4">
              Order #
              {order.id}:&nbsp;
              {order.address}
            </h2>
            {
              errorMessage
              && <Alert color="danger" fade isOpen={errorMessage !== ''}>{errorMessage}</Alert>
            }
            <Form>
              <FormGroup row>
                <Label sm={3}>Name</Label>
                <Col sm={9}>
                  <Label className="text-muted">{order.name}</Label>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Email</Label>
                <Col sm={9}>
                  <Label className="text-muted">{order.email}</Label>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Paid on</Label>
                <Col sm={9}>
                  <Label className={order.paidDateTime ? 'text-muted mr-3' : 'text-danger mr-3'}>
                    {(order.paidDateTime) ? new Date(order.paidDateTime).toLocaleDateString() : 'Not yet'}
                  </Label>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={3}>Invoice PDF</Label>
                <Col sm={9}>
                  {
                    order.paidDateTime
                      ? <Button color="link" className="p-0" onClick={handleDownloadInvoicePdf}>Download Invoice PDF</Button>
                      : <Label className="text-muted">(Available after invoice paid)</Label>
                  }
                  {' '}
                  {
                    isGeneratingInvoicePdf
                    && <LoadingSpinner text="Generating..." />
                  }
                </Col>
              </FormGroup>
              {
                order.virtualTourUrl
                && (
                  <FormGroup row>
                    <Label for="virtualTourUrl" sm={3}>Virtual Tour URL</Label>
                    <Col sm={9}><a href={cleanUrl(order.virtualTourUrl)} target="_blank" rel='noreferrer'>{cleanUrl(order.virtualTourUrl)}</a></Col>
                  </FormGroup>
                )
              }
              <FormGroup row>
                <Label for="manageFiles" sm={3}>Manage Files</Label>
                <Col sm={9}>
                  <FileBrowser orderId={order.id} orderGuid={guid} writeAccess={false} />
                </Col>
              </FormGroup>
            </Form>
          </div>
        )
      }
    </div >
  );
}
