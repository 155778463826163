import React, { useState, useEffect } from 'react';
import {
  Alert, Col, Form, Input, Row, Table,
} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { LoadingSpinner } from './LoadingSpinner';
import { CustomerOrderDto } from './Models/CustomerOrderDto';
import { TableSortLabel } from './TableSortLabel';
import sortHelper from '../utilities/sortHelper';
import { TablePaging } from './TablePaging';
import { CustomerDto } from './Models/CustomerDto';
import useFetch from '../hooks/useFetch';
import { NotFound } from './NotFound';

interface CustomerProps {
  customerGuid: string;
}

export function Customer() {
  const PAGE_SIZE = 20;

  const { customerGuid } = useParams<CustomerProps>();

  const [filterText, setFilterText] = useState<string>('');
  const [filteredOrders, setFilteredOrders] = useState<Array<CustomerOrderDto>>([]);

  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [pagesCount, setPagesCount] = useState<number>(0);

  const [sortName, setSortName] = useState<string>('Order ID');
  const [sortAscending, setSortAscending] = useState<boolean>(false);

  const handlePageChange = (e: React.MouseEvent<HTMLAnchorElement>, index:number) => {
    e.preventDefault();
    setCurrentPageIndex(index);
  };

  const handleSortChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, sortName: string, sortAscending: boolean) => {
    e.preventDefault();
    setSortName(sortName);
    setSortAscending(sortAscending);
  };

  function sortOrders(a: CustomerOrderDto, b: CustomerOrderDto) {
    let result: number = 0;

    switch (sortName) {
      case 'Order ID':
        result = sortHelper(a.id, b.id, sortAscending);
        break;
      case 'Address':
        result = sortHelper(a.address, b.address, sortAscending);
        break;
      case 'Email':
        result = sortHelper(a.email, b.email, sortAscending);
        break;
      case 'Name':
        result = sortHelper(a.name, b.name, sortAscending);
        break;
      case 'Paid':
        result = sortHelper(a.paidDateTime, b.paidDateTime, sortAscending);
        break;
    }

    return result;
  }

  const { data, isLoading, error } = useFetch<CustomerDto>(`api/customer/${customerGuid}`);

  useEffect(() => {
    if (!data) return;

    updateFilter(data.orders, filterText);
  }, [data]);

  function updateFilter(currentOrders: Array<CustomerOrderDto>, newFilterText: string) {
    setFilterText(newFilterText);

    const filtered = currentOrders.filter(
      (order: CustomerOrderDto) => {
        const filterTextLower = newFilterText.toLowerCase();
        const addressLower = order.address.toLowerCase();

        return (
          order.id.toString().includes(filterTextLower)
                    || addressLower.includes(filterTextLower)
                    || order.email.toLowerCase().includes(filterTextLower)
                    || order.name.toLowerCase().includes(filterTextLower)
                    || (filterTextLower === 'paid' && order.paidDateTime == null)
        );
      },
    );
    setFilteredOrders(filtered);
    setCurrentPageIndex(0);
    setPagesCount(Math.ceil(filtered.length / PAGE_SIZE));
  }

  if (error?.includes('404')) {
    return <NotFound />;
  }

  return (
    <div>
      <h2 className="mb-4">My Orders</h2>
      {
                isLoading
                && <LoadingSpinner />
            }
      {
        error
        && <Alert color="danger" fade isOpen={error !== ''}>{error}</Alert>
      }
      {
                (data) && (data.orders.length > 0)
                && (
                <div>
                  <Form>
                    <Row form className="mb-2">
                      <Col md={8} />
                      <Col md={4} className="text-right-sm">
                        <Input type="search" name="search" id="orderFilter" placeholder="Search" bsSize="sm" value={filterText} onChange={(e) => updateFilter(data.orders, e.target.value)} />
                      </Col>
                    </Row>
                  </Form>
                  <Table hover striped size="sm">
                    <thead>
                      <tr>
                        <td><TableSortLabel label="Order ID" sortName={sortName} sortAscending={sortAscending} onSortChange={handleSortChange} /></td>
                        <td><TableSortLabel label="Address" sortName={sortName} sortAscending={sortAscending} onSortChange={handleSortChange} /></td>
                        <td><TableSortLabel label="Email" sortName={sortName} sortAscending={sortAscending} onSortChange={handleSortChange} /></td>
                        <td><TableSortLabel label="Name" sortName={sortName} sortAscending={sortAscending} onSortChange={handleSortChange} /></td>
                        <td><TableSortLabel label="Paid" sortName={sortName} sortAscending={sortAscending} onSortChange={handleSortChange} /></td>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredOrders
                        .sort(sortOrders)
                        .slice(currentPageIndex * PAGE_SIZE, (currentPageIndex + 1) * PAGE_SIZE)
                        .map((order: CustomerOrderDto, index: number) => (
                          <tr key={index}>
                            <th scope="row"><Link to={`/order/${order.orderGuid}`}>{order.id}</Link></th>
                            <td>{order.address}</td>
                            <td>{order.email}</td>
                            <td>{order.name}</td>
                            <td className={order.paidDateTime ? '' : 'text-danger'}>
                              {(order.paidDateTime) ? new Date(order.paidDateTime).toLocaleDateString() : 'Not yet'}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <TablePaging currentPageIndex={currentPageIndex} pagesCount={pagesCount} onPageChange={handlePageChange} />
                </div>
                )
            }
    </div>
  );
}
