import React from 'react';
import {
    Button, Modal, ModalBody, ModalFooter,
} from 'reactstrap';

interface ConfirmationModalProps {
    confirmationText: string,
    question: string,
    isOpen: boolean,
    // eslint-disable-next-line no-unused-vars
    onConfirmClick: (e: React.MouseEvent<any, MouseEvent>) => void
    onToggle: () => void
}

export default function ConfirmationModal(props: ConfirmationModalProps) {
    const {
        confirmationText, question, isOpen, onConfirmClick, onToggle,
    } = props;

    return (
        <Modal isOpen={isOpen} toggle={onToggle} backdrop keyboard size="sm">
            <ModalBody>
                {question}
            </ModalBody>
            <ModalFooter>
                <Button id="ConfirmButton" color="danger" onClick={onConfirmClick}>{confirmationText}</Button>
            </ModalFooter>
        </Modal>
    );
}
