import React from 'react';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type OnSortChangeEvent = (
  target: React.MouseEvent<HTMLDivElement, MouseEvent>,
  sortName: string,
  sortAscending: boolean
) => void;

interface TableSortLabelProps{
  label: string,
  sortName: string,
  sortAscending: boolean,
  onSortChange: OnSortChangeEvent
}

export function TableSortLabel(props: TableSortLabelProps) {
  function isSelected() {
    return (props.label === props.sortName);
  }

  function sortChange(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const newSortAscending = isSelected() ? !(props.sortAscending) : true;
    props.onSortChange(e, props.label, newSortAscending);
  }

  let sortIcon: JSX.Element = <FontAwesomeIcon className="text-muted" icon={faSort} />;
  if (isSelected()) {
    sortIcon = props.sortAscending ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
  }

  return (
    <div className="text-nowrap" onClick={sortChange}>
      <span className="mr-2">{props.label}</span>
      {sortIcon}
    </div>
  );
}
