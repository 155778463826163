import { useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface ReCaptchaProps {
    nameOfUserAction: string,
    isCallingReCaptcha: boolean,
    onVerified: (token: string) => void
}

export default function ReCaptcha(props: ReCaptchaProps){
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        //console.log('calling recaptcha');
        const token = await executeRecaptcha(props.nameOfUserAction);
       
        //console.log(`recaptcha call complete: ${token}`);

        // send the token to our backend
        props.onVerified(token);

    }, [executeRecaptcha, props]);

    useEffect(() => {
        if(props.isCallingReCaptcha){
            handleReCaptchaVerify();
        }
        
    }, [handleReCaptchaVerify, props.isCallingReCaptcha]);
    
    return <div id="reCaptcha"></div>;
};